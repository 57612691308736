/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'easel2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.447.276a.5.5 0 00-.894 0L7.19 1H2.5A1.5 1.5 0 001 2.5V10h14V2.5A1.5 1.5 0 0013.5 1H8.809z"/><path pid="1" fill-rule="evenodd" d="M.5 11a.5.5 0 000 1h2.86l-.845 3.379a.5.5 0 00.97.242L3.89 14h8.22l.405 1.621a.5.5 0 00.97-.242L12.64 12h2.86a.5.5 0 000-1zm3.64 2l.25-1h7.22l.25 1z"/>',
    },
});
